'use strict';

/* Directives */
import {app} from '../app';


app.directive('appVersion', ['version', function (version) {
    return function (scope, elm, attrs) {
        elm.text(version);
    };
}])
.directive('iosSwitch', [function () {
    return {
        scope: {
            selected: '=',
            disabled: '=',
            loading: '=',
            name: '@',
            required: '@',
        },
        transclude: true,
        template: `
            <div ng-hide="loading">
                <input type="checkbox" ng-model="selected" ng-disabled="{{disabled}}" ng-required="{{required}}" name="{{name}}" class="ios-switch tinyswitch"/>
                <div><div></div></div>
            </div>
            <div ng-show="loading" style="overflow: hidden;padding-right: 10px;margin-top: -3px">
                <span class="glyphicon glyphicon-refresh glyphicon-spin" style="font-size: 21px"></span>
            </div>
        `,
        restrict: 'AE'
    }
}])

    .directive('passwordFormatter', [function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, controller) {
                controller.$formatters.push(function (value) {
                    if (value) {
                        return "********";
                    }
                    return "";
                });
            }
        }
    }])

    .directive('phoneNumberFormatter', [function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, controller) {
                controller.$formatters.push(function (value) {
                    if (value) {
                        return "+" + value.substr(0, attrs.phoneNumberFormatter.length) + " " + value.substr(attrs.phoneNumberFormatter.length);
                    }
                    return "";
                });
            }
        }
    }])

    .directive('offClick', [function ($document) {
        return function (scope: any, elm, attrs) {
            $document.on('click', function () {
                if (!attrs.isCollapsed) {
                    scope.isCollapsed = true;
                    scope.$apply();
                }
            });
        };
    }])

    .directive('spinner', [function () {
        return {
            transclude: true,
            restrict: 'AE',
            template: '<svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="8"></circle></svg>'
        }
    }])

    .directive('convertToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel: any) {
                ngModel.$parsers.push(function (val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    })

    .directive('fallbackSrc', function () {

        return {
            restrict: 'A',
            link: (scope, element: any, attr) => {

                // Listen for errors on the element and if there are any replace the source with the fallback source
                let errorHandler = () => {

                    // fallbackSrc may have changed since the link function ran, so try to grab it again.
                    let newSrc = attr.fallbackSrc;

                    if (newSrc && element[0].src !== newSrc) {
                        element[0].src = newSrc;
                    }
                    element.off('error', errorHandler);

                };

                element.on('error', errorHandler);

                scope.$on('$destroy', () => {
                    element.off('error', errorHandler);
                });

            }
        }

    })
    .directive('screenmode', function () {
        return {
            restrict: 'A',
            link: (scope: any, element, attr) => {
                let screenmode = attr.screenmode;
                if (screenmode) {
                    scope.$root.$watch('mode', () => {
                        if (scope.$root.mode != screenmode) {
                            element.addClass('ng-hide');
                        } else {
                            element.removeClass('ng-hide');
                        }
                    })
                }
            }
        }
    })

    .directive('resizeInput', function() {
        return {
            restrict: 'A',
            link: function(scope, element) {
                element.on('input', function() {
                    element[0].style.width = 'auto';
                    element[0].style.width = (element[0].scrollWidth +4) + 'px';
                });
            }
        }
    });







