'use strict';

import {app} from '../../app';
import {IHttpResponse, IHttpService, ILocationService, ILogService, ITimeoutService} from "angular";
import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {IAccountAutomaticBlocks, IAppModel, IBlock, ILastDelivery, IRootScope, ITemplateDeliveryTypes, ITemplateLayerClips} from "../../hpc";
import * as _ from "underscore";
import * as restangular from "restangular";
import {IResponse} from "restangular";
import {TrackerService} from "../../services/TrackerService";
import {DeliveryService} from "../../services/DeliveryService";
import {PrelistenService} from "../../services/PrelistenService";

export enum playStatus {
    PLAY,
    LOAD,
    STOP
}

export class EditAnnouncement extends AbstractComponent {

    public static $inject = ['$log', '$rootScope', '$scope', '$sessionStorage', 'Restangular', '$timeout', '$http', 'tracker', '$moment', 'properties','$location','deliveryService','prelistenService'];

    constructor(
        private $log: ILogService,
        private $rs: IRootScope,
        private $s,
        public appModel: IAppModel,
        private Rest: restangular.IService,
        private $timeout: ITimeoutService,
        private $http: IHttpService,
        private tracker: TrackerService,
        private $moment,
        private properties,
        private $location: ILocationService,
        private deliveryService: DeliveryService,
        private prelistenService: PrelistenService) {
        super();
    }

    public lastDelivery: ILastDelivery;
    public announcements;

    //scheduler bindings
    public begin: Date = new Date();
    public end: Date;
    public isActivateNow: boolean = true;
    public noEnd: boolean = true;
    public showEnd: boolean = false;


    public loading: boolean = true;
    public audioSupported;
    public textLayer;
    public deliveryTypes;
    public templateText: string;
    public currentList: any;
    public currentClip: any;
    public fileUrl: string;
    public noScheduler: boolean; //for autoAnnouncements
    public autoAnnouncementType: 'defaultAnnouncement'|'oooAnnouncement'; // oooAnnouncement/defaultAnnouncement
    public isEdit: boolean; //is this a new or existing autoAnnouncement
    public suggestionLists = {};
    public hasAutoDateBlock: boolean = false;


    $onInit(): void {
        this.$log.info('init Edit Announcement');

        this.noScheduler = this.$location.search().noScheduler;
        this.autoAnnouncementType = this.$location.search().type;
        this.isEdit = this.$location.search().isEdit;

        this.template = this.appModel.currentTemplate;
        !this.appModel.suggestionLists ? this.appModel.suggestionLists = {} : null;

        this.$s.$on('close', (data) => {
            this.$s.currentList = null;
            this.checkIsComplete();
        });

        this.$s.$on('refresh', (data) => {
            this.currentClip.isComplete = true;
            this.checkIsComplete();
        });

        this.$s.$watch('$ctrl.end', (newV:Date, oldV:Date) => {
            if ((newV instanceof Date && oldV instanceof Date && newV.getTime() === oldV.getTime()) || newV === oldV) {
                return;
            }
            console.log('end changed: ' + newV);
            this.appModel.currentAnnouncement.endDate = newV;
            this.createDisplaylist();
        });

        //prevent back button to leave route on open selectionList. Rather close the list
        this.$s.$on('$locationChangeStart', (event) => {
            if(this.currentList && this.currentList.length > 0){
                this.currentList = null;
                this.currentClip = null;
                event.preventDefault();
            }
        })

        this.audioSupported = () => {
            return this.prelistenService.audioSupported();
        };

        if(!this.noScheduler){
            //this.end = new Date(this.begin.getTime() + 24 * 60 * 60 * 1000);
            this.noEnd = true;
        }

        this.announcements = this.Rest.all('announcements');
        this.prepareAnnouncement();
    }


    $onChanges(onChangesObj: angular.IOnChangesObject): void {
        super.$onChanges(onChangesObj);
    }

    private prepareAnnouncement() {
        this.loading = false;
        let currentAnnouncement = this.appModel.currentAnnouncement;
        this.$log.debug('id: ' + currentAnnouncement.id + 'name: ' + currentAnnouncement.name);
        this.textLayer = currentAnnouncement.template.templateLayers[0].templateLayerClips;
        this.deliveryTypes = currentAnnouncement.template.templateDeliveryTypes;
        this.setDeliveryTypeSwitches();
        this.createDisplaylist();
    }

    /**
     * Creates the announcement from template id
     */
    // private createAnnouncement() {
    //     this.Rest.one('templates', this.appModel.currentTemplate.id).all('announcement').post(null)
    //         .then(
    //             (data) => {
    //                 this.appModel.currentAnnouncement = data;
    //                 this.prepareAnnouncement();
    //             },
    //             (data: IResponse) => {
    //                 //errors are handled in interceptor
    //             })
    //     ;
    // }

    /**
     * Enable and preset availabe DeliveryType switches
     */
    private setDeliveryTypeSwitches(): void {
        for (var i = 0; i < this.deliveryTypes.length; i++) {
            var tdt: ITemplateDeliveryTypes = this.deliveryTypes[i];
            tdt.selected = false;
            tdt.disabled = false;
            switch (tdt.deliveryType.id) {
                case 1:
                    if (this.appModel.account.phoneNumber) {
                        tdt.selected = true;
                        tdt.disabled = false;
                    }
                    break;
                case 2:
                    if (this.appModel.account.mobileNumber) {
                        tdt.selected = true;
                        tdt.disabled = false;
                    }
                    break;
                case 6:
                    if (this.appModel.account.mobileNumber) {
                        tdt.selected = true;
                        tdt.disabled = false;
                    }
                    break;
                case 7:
                    tdt.selected = true;
                    tdt.disabled = false;
                    break;
            }


        }
    }

    /**
     *
     */
    private async createDisplaylist() {
        console.log('createDisplaylist');
        var template = this.appModel.currentAnnouncement.template;
        this.templateText = "";
        var noCompleteCheck = false;
        var textLayerClips: ITemplateLayerClips[] = template.templateLayers[0].templateLayerClips;
        var accountAutomaticBlocks: IAccountAutomaticBlocks[] = this.appModel.account.accountAutomaticBlocks;
        for (const textLayerClip of textLayerClips) {
            this.templateText = this.templateText + textLayerClip.name + " ";

            //process automatic Blocks
            if (textLayerClip.type == "AutomaticBlock") {
                var staticBlock: IBlock = _.find(accountAutomaticBlocks, (item) => {
                    return item.automaticBlock.id == textLayerClip.clipId
                }).staticBlock;
                textLayerClip.name = staticBlock.name;
                textLayerClip.clipId = staticBlock.id;
                textLayerClip.type = staticBlock.type;
                textLayerClip.class = "automaticBlock";
                textLayerClip.isComplete = true;
            }
            //process manual Blocks
            else if (textLayerClip.type == "ManualBlock") {
                let announcementManualBlocks = this.appModel.currentAnnouncement.announcementManualBlocks;

                // use class attribute to determine if first load and if yes set name as title
                textLayerClip.class ? null : textLayerClip.title = textLayerClip.name;
                textLayerClip.class = "manualBlock";
                var listId: number = textLayerClip.suggestionListId;

                if(!this.suggestionLists[listId]){
                    await this.getListOptions(listId);
                }

                announcementManualBlocks?.some((block) => {
                    if (block.templateLayerClipId == textLayerClip.templateLayerClipId) {
                        textLayerClip.name = block.staticBlockName;
                        textLayerClip.isComplete = true;
                    }
                });

                if(textLayerClip.manualBlockType == "DAY"){
                    this.hasAutoDateBlock = true;
                    if(this.end){ //if we have a end date use it as default
                        let day = this.suggestionLists[listId][this.end.getDate() - 1 ]
                        await this.updateManualBlock(day, textLayerClip);
                    }
                    if(this.noScheduler){
                        textLayerClip.isComplete = true;
                    }
                    this.noEnd = false;
                }

                if(textLayerClip.manualBlockType == "MONTH"){
                    this.hasAutoDateBlock = true;
                    if(this.end){
                        let month = this.suggestionLists[listId][this.end.getMonth()]
                        await this.updateManualBlock(month, textLayerClip);
                    }
                    if(this.noScheduler){
                        textLayerClip.isComplete = true;
                    }
                    this.noEnd = false;
                }

            }
            //process Digit Blocks
            else if (textLayerClip.type == "DigitBlock") {
                let announcementDigitBlocks = this.appModel.currentAnnouncement.announcementDigitBlocks;

                announcementDigitBlocks?.some((block)=>{
                    if(block.templateLayerClipId == textLayerClip.templateLayerClipId){
                        textLayerClip.name = block.value;
                        textLayerClip.class = "digitBlock";
                    }
                });

                if (textLayerClip.name == "Mobilenumber") {
                    textLayerClip.name = this.appModel.account.mobileNumber;
                    noCompleteCheck = true;
                    this.updateDigitBlock(textLayerClip);
                } else if (textLayerClip.name == "Phonenumber") {
                    textLayerClip.name = this.appModel.account.phoneNumber;
                    noCompleteCheck = true;
                    this.updateDigitBlock(textLayerClip);
                } else {
                    textLayerClip.class ? null : textLayerClip.name = "";
                }

                textLayerClip.class = "digitBlock";

                //update Announcement Status
                textLayerClip.name == "" ? textLayerClip.isComplete = false : textLayerClip.isComplete = true;

            }
            //process Ki Blocks
            else if (textLayerClip.type == "KIBlock") {
                let announcementKIBlocks = this.appModel.currentAnnouncement.announcementKIBlocks;

                //use class attribut to determine if this is first load
                if(!textLayerClip.class){
                    textLayerClip.title = textLayerClip.name;
                    textLayerClip.name = "";
                }
                textLayerClip.class ? null : textLayerClip.name = "";

                textLayerClip.class = "kiBlock";

                announcementKIBlocks?.some((block)=>{
                    if(block.templateLayerClipId == textLayerClip.templateLayerClipId){
                        textLayerClip.name = block.value;
                        textLayerClip.class = "kiBlock";
                    }
                });

                //update Announcement Status
                textLayerClip.name == "" ? textLayerClip.isComplete = false : textLayerClip.isComplete = true;
                textLayerClip.maxLength ? null : textLayerClip.maxLength = 600;
            }


        }
        this.textLayer = textLayerClips;
        //prevent Check if Digitblock update is running as this async callback will trigger the check anyway
        if (!noCompleteCheck) {
            this.checkIsComplete();
        }
        //this.$s.$apply();
        this.loading = false;
    }

    /**
     *
     * @returns {boolean}
     */
    private checkIsComplete(): boolean {
        var textLayerClips: ITemplateLayerClips[] = this.appModel.currentAnnouncement.template.templateLayers[0].templateLayerClips;
        var isComplete: boolean = true;
        for (var i = 0; i < textLayerClips.length; i++) {
            if (textLayerClips[i].type != "StaticBlock" && textLayerClips[i].type != "Music") {
                textLayerClips[i].isComplete ? null : isComplete = false;
            }
        }
        this.$log.debug("IsComplete:" + isComplete);
        this.isComplete = isComplete;
        return isComplete;
    }

    /**
     *
     * @param listId
     */
    private async getListOptions(listId: number): Promise<void> {
        try {
            const data = await this.announcements.one('suggestionLists', listId).get();
            this.appModel.suggestionLists[listId] = data;
            this.suggestionLists = this.appModel.suggestionLists;
            console.log('fetched List Options for id: ' + listId);
        } catch (error) {
            // Handle errors locally if needed, even if globally handled
            console.error('Error fetching suggestion list:', error);
        }
    }



    private prelisten() {
        this.prelistenService.togglePrelisten(this.appModel.currentAnnouncement);
    }

    private download(mediaType?: { type, extension }) {
        this.prelistenService.download(this.appModel.currentAnnouncement, mediaType, this.template.name);
    }


    private locationChangeEventHandle;

    /**
     *
     * @param clip
     */
    private openList(clip: ITemplateLayerClips) {

        if(clip.manualBlockType != "DEFAULT"){
            this.showEnd = true;
            return;
        }

        this.currentList = this.appModel.suggestionLists[clip.suggestionListId];
        this.currentClip = clip;
        // Prevent Back Button history
        // this.locationChangeEventHandle = this.$rs.$on('$locationChangeStart', (event) => {
        //     this.currentList = null;
        //     this.currentClip = null;
        //     event.preventDefault();
        //     //removes the handle after call
        //     this.locationChangeEventHandle = null;
        // })
    }


    /**
     *
     * @param clip
     */
    private updateDigitBlock(clip: ITemplateLayerClips) {
        if (clip.name.length == 0) {
            clip.isComplete = false;
            return;
        }
        //prevent render of unchaged clips
        if(clip.name==clip.previousName){
            return;
        } else {
            clip.previousName = clip.name;
        }

        // prettyprint Number
        var value = clip.name;
        if (/^[0-9+]+$/.test(value)) {
            //clip.name =  value.substr(0,4) + " " + value.substr(4,3) + " " + value.substr(7,3) + " " + value.substr(10,20);}
            this.$http.get('prettyprintNumber.php', {params: {number: clip.name, lkz: this.extractLkz()}})
                .then((res: IHttpResponse<string>) => {
                    this.$log.debug('prettyprint return: ' + res.data);
                    if (/^[0-9 \n\r]+$/.test(res.data)) {
                        clip.name = res.data.replace(/(\r\n|\n|\r)/gm, "");
                    }
                    this.performUpdateDigitBlock(clip);
                }).catch((error: IResponse) => {
                this.$log.error("Error PrettyPrinting Number");
                this.performUpdateDigitBlock(clip);
            })
        } else {
            this.performUpdateDigitBlock(clip);
        }
    }

    public updateKiBlock(clip: ITemplateLayerClips) {
        if (clip.name.length == 0) {
            clip.isComplete = false;
            return;
        }
        //prevent render of unchaged clips
        if(clip.name==clip.previousName){
            return;
        } else {
            clip.previousName = clip.name;
        }
        this.performUpdateKiBlock(clip);
    }


    private extractLkz() {
        if (this.appModel.account.mobileNumber) {
            if (this.appModel.account.mobileNumber.substr(0, 1) === "1") {
                return "1";
            } else {
                return this.appModel.account.mobileNumber.substr(0, 2);
            }
        }
        return null;
    }

    //we need to Chain request not to run in concurency update situations with announcement version.
    private requestChain = [];
    private requestPending = false;

    /**
     *
     * @param clip
     */
    private performUpdateDigitBlock(clip: ITemplateLayerClips) {
        if (this.requestPending) {
            this.requestChain.push(clip);
            return;
        }
        this.requestPending = true;
        clip.isComplete = true;
        this.announcements.one(this.appModel.currentAnnouncement.id.toString())
            .one('digitBlocks', clip.templateLayerClipId)
            .post(clip.name, {}, {
                'announcementVersion': this.appModel.currentAnnouncement.version
            })
            .then((data) => {
                this.appModel.currentAnnouncement.version = data.version;
                this.$log.log('Digit Block gespeichert:' + clip.templateLayerClipId + "->" + clip.name);
                this.requestPending = false;
                this.nextRequest();
            }, (data: IResponse) => {
                //errors are handled in interceptor
            })
    }


    private performUpdateKiBlock(clip: ITemplateLayerClips){
        if (this.requestPending) {
            this.requestChain.push(clip);
            return;
        }
        this.requestPending = true;
        clip.isComplete = true;
        this.announcements.one(this.appModel.currentAnnouncement.id.toString())
        .one('kiBlocks', clip.templateLayerClipId)
        .post(null, clip.name, {
            'announcementVersion': this.appModel.currentAnnouncement.version
        },{
            'Content-Type': 'text/plain'
        })
        .then((data) => {
            this.appModel.currentAnnouncement.version = data.version;
            this.$log.log('Ki Block gespeichert:' + clip.templateLayerClipId + "->" + clip.name);
            this.requestPending = false;
            this.nextRequest();
        }, (data: IResponse) => {
            console.log('Error saving Ki Block: ' + data);
            this.nextRequest();
        })
    }

    private nextRequest(){
        if (this.requestChain.length > 0) {
            let clip = this.requestChain.shift();
            if(clip.type == "DigitBlock") {
                this.performUpdateDigitBlock(clip);
            } else if (clip.type == "KIBlock") {
                this.performUpdateKiBlock(clip);
            }
        } else {
            this.checkIsComplete()
        }
    }

    public async updateManualBlock(listItem, clip: ITemplateLayerClips) {
        console.log('updateManualBlock: ' + clip.templateLayerClipId + " to: " + listItem.name);
        clip.isComplete = false;
        this.$rs.showLoading = true;

        try {
            const data = await this.Rest.one('announcements', this.appModel.currentAnnouncement.id).one('manualBlocks', clip.templateLayerClipId).post(listItem.id, {}, {});

            console.log('Manual Block gespeichert:' + clip.templateLayerClipId);
            this.appModel.currentAnnouncement.version = data.version;
            clip.isComplete = true;
            this.checkIsComplete();
        } catch (error) {
            this.$log.error('Error replace manualBlock');
            this.$rs.errormessage = 'Error replacing Block';
        }

        clip.name = listItem.name;
        clip.clipId = listItem.id;

        this.currentList = null;
        this.$s.$apply();
    }


    /**
     *
     */
    public async activateAnnouncement() {
        if (this.appModel.demoMode) {
            this.$rs.errormessage = "ERROR_NO_ACTIVATION_IN_DEMO";
            return;
        }
        if (!this.isComplete) {
            this.$rs.errormessage = "Bitte vervollständigen Sie zunächst die Ansage";
            this.$log.warn('Announcement incomplete.');
            return;
        }
        if (!this.noEnd && this.begin && this.end < this.begin) {
            this.$rs.errormessage = 'ERROR_ENDDATE_BEFORE_BEGINDATE';
            return;
        }
        try {
            await this.deliveryService.activateAnnouncement(this.appModel.currentAnnouncement, this.isActivateNow ? null : this.begin, this.noEnd ? null : this.end, this.deliveryTypes);
            this.$rs.$back();
        } catch (error) {
            this.$log.error('Error during announcement activation:', error);
        }
    }

    public async saveAnnouncement() {
        if (!this.isComplete) {
            this.$rs.errormessage = "Bitte vervollständigen Sie zunächst die Ansage";
            this.$log.warn('Announcement incomplete.');
            return;
        }

        this.Rest.one('/accounts/me/').post(this.autoAnnouncementType +"/" + this.appModel.currentAnnouncement.id)
        .then((data) => {
            this.$location.path("/main").search({});
        });

    }

    public unsetAnnouncement() {
        this.Rest.one('/accounts/me/' + this.autoAnnouncementType).remove()
        .then((data) => {
            this.$location.path("/main").search({});
        });
    }


    public hasDeliveryType(id: number): boolean {
        return this.deliveryTypes.find(tdt => tdt.deliveryType.id === id) !== undefined;
    }

    public canActivate() {
        //check if deliveryTypes array contains at least one element with id!=4
        return this.isComplete && this.deliveryTypes?.filter(tdt => tdt.deliveryType.id != 4).length > 0;
    }


}

app.component('editAnnouncement', {
    bindings: {
        // type: '<',
        // noScheduler: '<',
    },
    templateUrl: 'ts/components/editAnnouncement/editAnnouncement.html',
    controller: EditAnnouncement
})