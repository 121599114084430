'use strict';

import {app} from '../../app';
import {ILogService, ITimeoutService} from "angular";
import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {IAppModel, ICalendar, ILastDelivery, IRootScope} from "../../hpc";
import * as _ from "underscore";


export class CurrentAnnouncement extends AbstractComponent {

    public static $inject = ['$log', '$rootScope', '$scope','$sessionStorage', 'Restangular', '$timeout', 'prelistenService'];

    public progress: number;
    private progressRunning;
    private calendarRefreshListener;

    constructor(private $log: ILogService,
                private $rs: IRootScope,
                private $scope,
                private appModel: IAppModel,
                private Rest,
                private $timeout: ITimeoutService,
                private prelistenService) {
        super();
    }

    $onInit(): void {
        this.$log.info('init log info');

        this.progress = 0;
        const initTime = Date.now();

        //this.appModel.lastDelivery = null;
        this.getCurrentAnnouncement();
        this.getCalendars();

        //update current announcement on resume event (broadcasted by cordova wrapper app)
        this.$rs.$on('appresume', () => {
            console.log('resume - reloading currentAnnouncement');
            this.appModel.lastDelivery = null;
            this.getCurrentAnnouncement();
            this.getCalendars();
        })

        this.calendarRefreshListener = this.$rs.$on('calendarRefresh', () => {
            console.log('calendarRefresh - reloading currentAnnouncement');
            this.appModel.lastDelivery = null;
            this.getCurrentAnnouncement();
            this.getCalendars();
        });

        // Deregister the event listener when the component is destroyed
        this.$scope.$on('$destroy', () => {
            if (this.calendarRefreshListener) {
                this.calendarRefreshListener();
            }
        });
    }


    /**
     * get the current Announcement from REST
     */
    private getCurrentAnnouncement() {
        this.Rest.one('accounts/me', null).one('currentAnnouncement').get()
            .then((data) => {
                if (!data) {
                    this.appModel.lastDelivery = {} as ILastDelivery;
                } else {
                    this.$log.info('success get currentAnnouncement:' + data);
                    if (data.calendars[0].deliveryStatus.id == 2) {
                        this.$log.log('Delivery Pending, start Timer');
                        this.appModel.deliveryPending = true;
                        if(this.appModel.lastDelivery?.calendar.id != data.calendars[0].id) {
                            this.appModel.progressInit = Date.now();
                        }
                        if(!this.progressRunning){
                            this.doProgress();
                        }
                        this.progress = Date.now() - this.appModel.progressInit;
                        this.$timeout(() => {
                            return this.getCurrentAnnouncement()
                        }, 5000)
                    }
                    this.appModel.deliveryPending = false;
                    this.appModel.lastDelivery = data;
                    this.appModel.lastDelivery.calendar = data.calendars[0];
                }
            }, function (data) {
                // TODO Error Handling
                this.$log.error('Error get currentAnnouncement')
            })
    }

    private getCalendars() {
        this.Rest.one('accounts/me', null).one('listAnnouncements').get()
        .then((data) => {
            this.$log.info('Success listAnnouncements');
            var announcements = [];

            _.each(data, (announcement: ILastDelivery) => {
                _.each(announcement.calendars, (calendar: ICalendar) => {
                    if (calendar.deliveryStatus.id == 1) {
                        var tmpAnnouncement: ILastDelivery = _.clone(announcement);
                        tmpAnnouncement.calendars = null;
                        tmpAnnouncement.calendar = calendar;
                        tmpAnnouncement.isAutoAnnouncement = (announcement.id == this.appModel.storedAnnouncements?.defaultAnnouncement?.id || announcement.id == this.appModel.storedAnnouncements?.oooAnnouncement?.id);
                        announcements.push(tmpAnnouncement);

                        // Check if calendar.begin is within 60 seconds before or after now
                        const now = new Date();
                        const beginTime = new Date(calendar.begin);
                        const sixtySecondsBefore = new Date(now.getTime() - 60000);
                        const sixtySecondsAfter = new Date(now.getTime() + 60000);

                        if (beginTime >= sixtySecondsBefore && beginTime <= sixtySecondsAfter) {
                            // Set a timeout to redo getCalendars in 5 seconds
                            this.$timeout(() => {
                                this.getCalendars();
                                this.getCurrentAnnouncement();
                            }, 5000);
                        }
                    }
                });
            });

            this.appModel.announcements = announcements;
        }, (data) => {
            // TODO Error Handling
            this.$log.error('Error listAnnouncements');
        });
    }



    private doProgress(){
        this.progressRunning = true;
        if(this.progress <= 120000){
            this.$timeout(() => {
                this.progress = Date.now() - this.appModel.progressInit;
                this.doProgress();
            },200, true)
        }
    }

    /**
     * Remove Scheduled Calendar
     *
     * @param announcement
     */
    public removeCalendar(announcement: ILastDelivery) {
        this.$rs.showLoading = true;
        var calendar = this.Rest.one('announcements', announcement.id).one('calendars', announcement.calendar.id);
        calendar.remove({
            announcementVersion: announcement.version,
            calendarVersion: announcement.calendar.version
        })
            .then((data) => {
                this.$log.info('removed calendar success');
                this.getCalendars();
            }, (data) => {
                this.$log.error('removing calendar failed');
                this.$rs.errormessage = 'ERROR_REMOVING_ANNOUNCEMENT';
            })
    }

    public prelisten(announcement) {
        this.prelistenService.togglePrelisten(announcement);
    }
}

app.component('currentAnnouncement', {
    bindings: {},
    templateUrl: 'ts/components/currentAnnouncement/currentAnnouncement.html',
    controller: CurrentAnnouncement
})